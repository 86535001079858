import React, { createContext } from 'react'
import { ThemeProvider } from 'styled-components'
import useTheme from './useTheme'

export const ModeContext = createContext()

const withTheme = Component => {
  const EnhancedComponent = props => {
    const [appTheme, mode, rotateMode] = useTheme()
    return (
      <ThemeProvider theme={appTheme}>
        <ModeContext.Provider value={{ mode, rotateMode }}>
          <Component {...props} />
        </ModeContext.Provider>
      </ThemeProvider>
    )
  }

  return EnhancedComponent
}

export default withTheme

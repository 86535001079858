import styled from 'styled-components'
import {
  variant,
  backgroundImage,
  color,
  layout,
  space,
  grid,
  position,
  flexbox
} from 'styled-system'

const Section = styled.div(
  ({ theme }) => variant({
    variants: {
      parallax: {
        // backgroundAttachment: 'fixed',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      },
      gradient: {
        background: `linear-gradient(181.67deg, ${ theme.colors.gradient.start } 14.37%, ${ theme.colors.gradient.stop } 87.4%)`
      }
    }
  }),
  position,
  backgroundImage,
  layout,
  color,
  space,
  grid,
  flexbox
)

Section.defaultProps = {
  bg: 'background.primary',
  p: 4,
  width: '100%'
}

export default Section

import { useState, useEffect } from 'react'
import useGetBackgroundImages from './useGetBackgroundImages'

const getFluidSet = (imageSet, imageType) => [
  imageSet[`${ imageType }grad`].childImageSharp.fixed,
  {
    ...imageSet[`${ imageType }690`].childImageSharp.fixed,
    media: `(min-width: 500px)`
  },
  {
    ...imageSet[`${ imageType }960`].childImageSharp.fixed,
    media: `(min-width: 690px)`
  },
  {
    ...imageSet[`${ imageType }1200`].childImageSharp.fixed,
    media: `(min-width: 960px)`
  },
  {
    ...imageSet[`${ imageType }1800`].childImageSharp.fixed,
    media: `(min-width: 1200px)`
  }
]

const useBackgroundImage = mode => {
  const imageSet = useGetBackgroundImages()
  const imageType = mode === 'light' ? 'light' : 'dark'

  const [background, setBackground] = useState(getFluidSet(imageSet, imageType))

  useEffect(() => {
    setBackground(getFluidSet(imageSet, imageType))
  }, [setBackground, imageSet, imageType])

  return background
}

export default useBackgroundImage

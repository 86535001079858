import React from 'react'
import Image from 'gatsby-image'
import { Link } from 'gatsby'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import Section from './../components/Section'
import Content from './../components/Content'
import Wave from './../components/Wave'
import Text from './../elements/Text'
import StreamingImage from './../components/Streaming'
import Spacer from './../elements/Spacer'
import useGetStreamingImage from '../useGetStreamingImage'
import Button from '../components/Button'

const StreamingPanel = () => {
  const { image } = useGetStreamingImage()

  return (
    <Section position='relative' py={[60, 32]}>
      <Content
        display='flex'
        justifyContent='space-between'
        flexWrap='wrap'
        px={[2, 2, 3, 7]}
      >
        <Content
          p={0}
          pr={[0, 0, 0, 0, 4]}
          display='flex'
          justifyContent='center'
          textAlign={['center', 'center', 'center', 'center', 'inherit']}
          alignItems={['center', 'center', 'center', 'center', 'inherit']}
          flexDirection='column'
          flexGrow='1'
          flexBasis='50%'
        >
          <Text variant='title' fontSize={[36, 48]}>
            Live Streaming
          </Text>
          <Text as='p'>
            Embed Twitch, Mixer, and YouTube directly on your page so your
            followers can watch, chat, and subscribe from your very own custom
            website. Control your audience's experience by putting the spotlight on you.
          </Text>
          <Spacer />
          <Link to='/pricing' style={{ textDecoration: 'none' }}>
            <Button
              dataElementLocation={DataElementLocations.BODY}
              dataElementLabel='websitebuilder-fos-gaming-get-started-button-streaming'
              dataElementId='websitebuilder-fos-gaming-get-started-button-streaming'
              mb={2}
            >
              Get Started
            </Button>
          </Link>
        </Content>
        <StreamingImage>
          <Image fluid={image.childImageSharp.fluid} alt='streaming screenshot' />
        </StreamingImage>
      </Content>
      <Wave variant='spacer' />
      <Wave fill='gradient.start' />
    </Section>
  )
}

export default StreamingPanel

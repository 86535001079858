import styled from 'styled-components'
import { variant, color, space, border, display } from 'styled-system'
import css from '@styled-system/css'

const Button = styled.button(
  color,
  ({ width, fullWidth }) =>
    css({
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: fullWidth ? '100%' : width || 200,
      '&:focus': {
        outlineColor: 'brand.primary'
      }
    }),
  border,
  space,
  display,
  ({ bg, color }) =>
    variant({
      variants: {
        filled: {
          bg: bg || 'brand.primary',
          color: color || (bg ? 'text' : 'brand.primaryText')
        },
        text: {
          bg: 'transparent',
          border: 0,
          color: color || 'text'
        }
      }
    })
)

Button.defaultProps = {
  display: 'flex',
  variant: 'filled',
  borderRadius: '50px',
  paddingY: 2,
  paddingX: 3,
  borderStyle: 'none'
}

export default Button

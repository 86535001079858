import { useStaticQuery, graphql } from 'gatsby'

const useGetStreamingImage = () =>
  useStaticQuery(graphql`
    query StreamingImg {
      image: file(relativePath: { eq: "gaming_mock_ui.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

export default useGetStreamingImage

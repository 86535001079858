import React, { useContext } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useTheme,
  useMediaQuery,
  makeStyles
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { ModalContext } from './../ModalContext'
import Box from './../elements/Box'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))

const SurveyModal = () => {
  const { open, closeModal } = useContext(ModalContext)
  const modalStyles = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Dialog
      open={open}
      onClose={closeModal}
      className={modalStyles.root}
      fullScreen={fullScreen}
    >
      <DialogTitle id='alert-dialog-title'>
        <IconButton className={modalStyles.closeButton} onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' justifyContent='center'>
          <iframe
            src='https://docs.google.com/forms/d/e/1FAIpQLSfcgzmTI4ftsk9eHvxHs85sHFvbo2Blu9SIC5MeMmgZj6_Ivw/viewform?embedded=true'
            width='640'
            height='1221'
            frameBorder='0'
            marginHeight='0'
            marginWidth='0'
          >
            Loading…
          </iframe>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SurveyModal

import { useState, useEffect } from 'react'
import merge from 'lodash/merge'
import get from 'lodash/get'

import appTheme from './theme'

const getTheme = mode =>
  merge({}, appTheme, {
    colors: get(appTheme.colors.modes, mode, appTheme.colors)
  })

const themes = ['dark', 'light', 'candy']

const useTheme = () => {
  const [mode, setMode] = useState(themes[0])
  const [theme, setTheme] = useState(getTheme(mode))

  const rotateMode = () => {
    const current = themes.indexOf(mode)
    const nextMode =
      current + 1 >= themes.length ? themes[0] : themes[current + 1]
    setMode(nextMode)
  }

  useEffect(() => {
    setTheme(getTheme(mode))
  }, [mode])

  return [theme, mode, rotateMode]
}

export default useTheme

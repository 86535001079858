import React, { useRef } from 'react'
import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import { string, func } from 'prop-types'

import Box from './../elements/Box'
import Button from './../components/Button'

const SearchInput = styled.input`
  border-radius: 50px;
  border-style: none;
  padding: 0 12px;
  flex-grow: 1;
  width: 100px;
`

const Search = ({
  placeHolder = '',
  handleOnSearch,
  color = 'brand.primary',
  ...rest
}) => {
  const searchRef = useRef(null)

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      handleOnSearch(searchRef.current.value)
    }
  }

  return (
    <Box
      bg={color}
      borderRadius={50}
      overflow='hidden'
      p={1}
      justifyContent='space-between'
    >
      <SearchInput
        aria-label='search input'
        placeholder={placeHolder}
        ref={searchRef}
        onKeyDown={onKeyDown}
      />
      <Button
        variant='text'
        aria-label='search button'
        color='brand.primaryText'
        icon={SearchIcon}
        display={['flex', 'none']}
        width={50}
        onClick={() => handleOnSearch(searchRef.current.value)}
        dataElementLocation={DataElementLocations.BODY}
        dataElementLabel='websitebuilder-fos-gaming-search-domain-button'
        dataElementId='websitebuilder-fos-gaming-search-domain-button'
        {...rest}
      />
      <Button
        variant='text'
        color='brand.primaryText'
        display={['none', 'flex']}
        width={125}
        onClick={() => handleOnSearch(searchRef.current.value)}
        dataElementLocation={DataElementLocations.BODY}
        dataElementLabel='websitebuilder-fos-gaming-search-domain-button'
        dataElementId='websitebuilder-fos-gaming-search-domain-button'
        {...rest}
      >
        Search
      </Button>
    </Box>
  )
}

Search.propTypes = {
  color: string,
  handleOnSearch: func,
  placeHolder: string
}

export default Search

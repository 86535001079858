import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    transition: background-color 0.2s ease-in, color 0.2s ease-in,
      fill 0.2s ease-in, background-image 0.2s ease-in, background 0.2s ease-in;
  }

  html { 
    background-color: ${ ({ theme }) => theme.colors.background.secondary };
  }


  /* overwrite styles set by leaking scss */
  #___gatsby, body {
    background: unset !important;
  }

  header {
    background: ${ ({ theme }) => theme.colors.navbar.background } !important;
    color: ${ ({ theme }) => theme.colors.text } !important;

    .signup-button {
      background-color: ${ ({ theme }) => theme.colors.navbar.button } !important;
      
      .MuiButton-label {
        color: ${ ({ theme }) => theme.colors.navbar.color } !important;
      }
    }
  }
`

export default GlobalStyles

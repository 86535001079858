import styled from 'styled-components'
import { space, layout } from 'styled-system'

const StreamingImage = styled.div(
  {
    height: 'auto',
    flexGrow: 1
  },
  layout,
  space
)

StreamingImage.defaultProps = {
  width: [1, null, null, null, 1 / 2]
}

export default StreamingImage

import React, { useState, createContext } from 'react'
import { node } from 'prop-types'

const ModalContext = createContext()

const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <ModalContext.Provider value={{ open, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: node
}

export default ModalProvider

export { ModalContext, ModalProvider }

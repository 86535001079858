import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { color, space, position, layout, variant } from 'styled-system'
import { string } from 'prop-types'

const WaveSVG = () => (
  <svg viewBox='0 0 1642 144' xmlns='http://www.w3.org/2000/svg'>
    <path d='m733.505631,26.7607c-136.7409,35.19252 -281.97931,65.39025 -416.98619,74.41488c-126.90512,7.87091 -275.53587,-5.88605 -397.51898,-72.31169l73.52208,116.63207l1861.64461,0l-2.19014,-145.49596c-132.85118,101.2384 -167.99438,91.82945 -269.349,98.11528c-135.00172,9.02463 -309.94333,-19.7858 -441.4832,-63.09756c-131.49853,-43.9656 -270.94168,-42.79571 -407.63918,-8.25702z' />
  </svg>
)

const Wave = ({ fill, ...rest }) => {
  const theme = useContext(ThemeContext)
  const svgFill = color({ color: fill, theme }).color

  return (
    <ImageWrapper fill={svgFill} {...rest}>
      <WaveSVG />
    </ImageWrapper>
  )
}

Wave.defaultProps = {
  fill: 'background.primary'
}

Wave.propTypes = {
  fill: string
}

const ImageWrapper = styled.div(
  ({ fill }) =>
    `
      pointer-events: none;
      svg {
        filter: drop-shadow( 0px 30px 40px rgba(0, 0, 0, .7));
        path {
          fill: ${ fill };
        }
      }
    `,
  space,
  position,
  layout,
  variant({
    variants: {
      spacer: {
        visibility: 'hidden',
        position: 'relative'
      }
    }
  })
)

ImageWrapper.defaultProps = {
  position: 'absolute',
  bottom: -1,
  left: 0,
  width: 1
}

export default Wave

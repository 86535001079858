import React from 'react'
import { object, node, oneOfType, arrayOf } from 'prop-types'
import { withDataProperty, DataElementTypes } from '@eig-builder/core-utils/helpers/tagging-helper'

import StyledButton from './StyledButton'

const Button = ({ icon: Icon, children, ...rest }) => (
  <StyledButton {...rest}>
    {Icon && <Icon fontSize='inherit' />}
    {children}
  </StyledButton>
)

Button.propTypes = {
  icon: object,
  children: oneOfType([node, arrayOf(node)])
}

export default withDataProperty(Button, DataElementTypes.BUTTON)

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from './../components/layout'
import SEO from '../components/seo'

// custom components using styled-components / styled-system for gaming FoS
import {
  ModalProvider,
  HeaderPanel,
  StreamingPanel,
  BubblePanel,
  SurveyModal,
  GlobalStyles,
  withTheme
} from './../components/gaming'

const Gaming = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )
  return (
    <Layout>
      <SEO
        title='Gaming'
        keywords={[`web site`, `site builder`, `gaming`, `twitch`, `streaming`, `brand`, `logo builder`, `merch`, `mixer`]}
        description='A website creator designed for gamers and streamers.
        Stream, sell, and build with integrations like Twitch and Mixer, merch, a custom logo builder, and more.'
        pageType={'product_page'}
        flowType={'gaming'}
        image={`${ site.siteMetadata.siteUrl }/images/social-preview-1200x.png`}
      />
      <GlobalStyles />
      <ModalProvider>
        <HeaderPanel />
        <StreamingPanel />
        <BubblePanel />
        <SurveyModal />
      </ModalProvider>
    </Layout>
  )
}

export default withTheme(Gaming)

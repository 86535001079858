import styled, { css } from 'styled-components'
import { variant, color, typography } from 'styled-system'

const Text = styled.span(
  ({ center, block }) => css`
    ${ center && `text-align: center;` }
    ${ block && `display: inline-block;` }
  `,
  variant({
    variants: {
      title: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: [30, 40, 50, 70],
        lineHeight: ['40px', '60px', '83px'],
        textTransform: 'uppercase'
      },
      default: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: [12, 18],
        lineHeight: ['20px', '36px']
      }
    }
  }),
  color,
  typography
)

Text.defaultProps = {
  color: 'text',
  variant: 'default',
  block: false
}

export default Text

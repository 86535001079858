import React from 'react'
import { Link } from 'gatsby'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import { goTo } from './../../../helpers/go-to-helper'

import Section from './../components/Section'
import Content from './../components/Content'
import Bubble from './../components/Bubble'
import Text from './../elements/Text'
import Spacer from './../elements/Spacer'
import Search from './../components/Search'
import Button from '../components/Button'

const BubblePanel = () => {
  const handleOnSearch = searchQuery => {
    goTo(`${ process.env.GATSBY_SEARCH_DOMAIN_URL }${ searchQuery }`)
  }

  return (
    <Section bg='unset' p={0}>
      <Section
        position='relative'
        bg='unset'
        variant='gradient'
        p={[1, 2, 4, 4, 4]}
        pt={[2, 0]}
      >
        <Content px={[2, 2, 3, 3, 7]}>
          <Bubble>
            <Content
              px={[4, 5, 5, 5]}
              py={[4, 5, 5, 5]}
              pr={[4, 5, 5, 5]}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              flex='4'
              maxWidth={850}
            >
              <Text
                variant='title'
                as='h1'
                fontSize={[30, null, null, 48]}
                lineHeight={['42px', null, null, '57px']}
              >
                Your merch. your terms.
              </Text>
              <Text>
                Your site comes with its own online store so you can sell your
                merchandise the way you want to. Give your community an easier way to
                buy — directly from your website! Start earning and spreading your brand today.
              </Text>
              <Link to='/pricing' style={{ textDecoration: 'none' }}>
                <Button
                  dataElementLocation={DataElementLocations.BODY}
                  dataElementLabel='websitebuilder-fos-gaming-get-started-button-merch'
                  dataElementId='websitebuilder-fos-gaming-get-started-button-merch'
                  mt={3}
                >
                  Get Started
                </Button>
              </Link>
            </Content>
          </Bubble>
        </Content>
        <Spacer size={[3, 3, 5]} />
        <Content
          display='grid'
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, minmax(350px, 2fr))']}
          gridGap={[3, 3, 5]}
          px={[2, 2, 3, 3, 7]}
        >
          <Bubble>
            <Content
              p={[4, 5, 5, 5]}
              display='flex'
              flexDirection='column'
              justifyContent='center'
            >
              <Text
                variant='title'
                as='h1'
                fontSize={[30, 48]}
                lineHeight={['42px', '57px']}
              >
                Free Logo Builder
              </Text>
              <Text>
                Stand out with a unique logo tailored to your brand.
                Feed a little info into our A.I.-powered logo builder and get
                hundreds of high-res logo options.
              </Text>
              <Link to='/pricing' style={{ textDecoration: 'none' }}>
                <Button
                  dataElementLocation={DataElementLocations.BODY}
                  dataElementLabel='websitebuilder-fos-gaming-get-started-button-logo'
                  dataElementId='websitebuilder-fos-gaming-get-started-button-logo'
                  mt={3}
                >
                  Get Started
                </Button>
              </Link>
            </Content>
          </Bubble>
          <Bubble>
            <Content
              p={[4, 5, 5, 5]}
              display='flex'
              flexDirection='column'
              justifyContent='center'
            >
              <Text
                variant='title'
                as='h1'
                fontSize={[30, 48]}
                lineHeight={['42px', '57px']}
              >
                Claim Your Domain
              </Text>
              <Text fontSize={[12, 18]} lineHeight={['19px', '36px']}>
                Your domain is more than your site’s address. It’s your brand’s
                front door. Level up your brand with a personalized and
                professional domain name.
              </Text>
              <Spacer />
              <Search
                handleOnSearch={handleOnSearch}
                placeHolder='YourGamerHandle.tv'
              />
            </Content>
          </Bubble>
        </Content>
      </Section>
    </Section>
  )
}

export default BubblePanel

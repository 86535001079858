const WSB_COLOR = {
  brand: {
    primary: '#FFB947',
    primaryText: '#571600'
  },
  heroText: '#FFF',
  text: '#F6F6F6',
  dark: '#1E1C26',
  red: '#FF213B',
  purple: '#7852C9',
  green: '#35C99C',
  lightGray: '#F6F6F6',
  navbar: {
    background: '#3082ED',
    button: '#FFB947',
    color: '#571600'
  },
  gradient: {
    start: '#ffffff',
    stop: '#ffffff',
  },
  background: {
    primary: '#146DE1',
    secondary: '#ffffff',
    wallpaper: '#173B8D'
  },
  card: '#146DE1'
}

const GAMING_COLOR = {
  brand: {
    primary: '#E00029',
    primaryText: '#F6F6F6'
  },
  heroText: '#F6F6F6',
  text: '#F6F6F6',
  dark: '#1E1C26',
  red: '#FF213B',
  purple: '#7852C9',
  green: '#35C99C',
  lightGray: '#F6F6F6',
  navbar: {
    background: '#7852C9',
    button: '#c9afff',
    color: '#472198'
  },
  gradient: {
    start: '#855AE1',
    stop: '#5325B7'
  },
  background: {
    primary: '#5325B7',
    secondary: '#855AE1',
    wallpaper: '#270A4F'
  },
  card: '#1E1C26'
}

const PINK_COLOR = {
  brand: {
    primary: '#6F3BE0',
    primaryText: '#FFFFFF'
  },
  heroText: '#F6F6F6',
  text: '#1E1C26',
  navbar: {
    background: '#854DFF',
    button: '#4C22A7',
    color: '#FFFFFF'
  },
  gradient: {
    start: '#F6F2FF',
    stop: '#C7ACFF'
  },
  background: {
    primary: '#E2D4FE',
    secondary: '#C7ACFF',
    wallpaper: '#270A4F'
  },
  card: '#FFFFFF'
}

const theme = {
  colors: {
    ...WSB_COLOR,
    modes: {
      dark: {
        ...GAMING_COLOR
      },
      candy: {
        ...PINK_COLOR
      }
    }
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [
    '40em', // 640px
    '56em', // 896px
    '64em', // 1024px,
    '92.5em' // 1480px
  ]
}

export default theme

import styled from 'styled-components'
import { flexbox, space, layout, display, border, color, position } from 'styled-system'

const Box = styled.div(flexbox, space, layout, display, position, border, color)

Box.defaultProps = {
  display: 'flex'
}

export default Box

import React, { useState, useRef, useEffect, useContext } from 'react'
import { string, func } from 'prop-types'
import GatsbyBackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'

import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import Section from './../components/Section'
import Content from './../components/Content'
import Wave from '../components/Wave'
import Text from './../elements/Text'
import Spacer from './../elements/Spacer'
import Button from './../components/Button'
import Box from './../elements/Box'
import useBackgroundImage from './../useBackgroundImage'
import useWindowDimensions from './../useWindowDimensions'
import { ModeContext } from './../withTheme'
import styled from 'styled-components'

const Toggle = ({ mode, rotateMode }) => {
  const handleChange = () => {
    rotateMode()
  }

  return (
    <Button
      width={80}
      borderRadius={0}
      p={2}
      bg='rgba(0,0,0,0.4)'
      borderWidth='2px'
      borderStyle='solid'
      borderColor='navbar.background'
      color='white'
      onClick={handleChange}
      dataElementLocation={DataElementLocations.HERO}
      dataElementLabel='websitebuilder-fos-gaming-toggle-theme-switch'
      dataElementId='websitebuilder-fos-gaming-toggle-theme-switch'
    >
      {mode}
    </Button>
  )
}

Toggle.propTypes = {
  mode: string,
  rotateMode: func
}

const WallpaperWrapper = styled.div({
  height: ({ dynamicHeight }) => `${ dynamicHeight }px`,
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: -1
})

const BackgroundImage = styled(GatsbyBackgroundImage)`
  width: 100%;
  height: 100%;
  background-color: ${ ({ theme }) =>
    theme.colors.background.wallpaper } !important;
`

const HeaderPanel = () => {
  const { width } = useWindowDimensions()

  const { mode, rotateMode } = useContext(ModeContext)
  const sectionRef = useRef()
  const [dim, setDim] = useState({ height: 620, top: 80 })
  const background = useBackgroundImage(mode)

  useEffect(() => {
    const { height } = sectionRef.current
      ? sectionRef.current.getBoundingClientRect()
      : { height: 620 }
    setDim({ height, top: 136 })
  }, [width])

  return (
    <Section
      variant='parallax'
      bg='unset'
      position='relative'
      p={[60, 32]}
      ref={sectionRef}
    >
      <WallpaperWrapper dynamicHeight={dim.height + dim.top}>
        <BackgroundImage
          fixed={background}
        />
      </WallpaperWrapper>
      <Box position='absolute' top={0} right={0} margin={2}>
        <Toggle mode={mode} rotateMode={rotateMode} />
      </Box>
      <Content
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        maxWidth={[768, null, null, 805]}
        margin='0 auto'
      >
        <Content maxWidth={[400, 500, 805]}>
          <Text variant='title' as='h1' center color='heroText'>
            The Brand Builder for Gamers
          </Text>
        </Content>
        <Spacer />
        <Content center maxWidth={[400, 450]}>
          <Text center color='heroText' fontSize={[14, 16]}>
            Level up your brand with a website builder made for you. Show off
            your streams, connect with your followers, and sell your merch all
            in one place.
          </Text>
          <Spacer />
          <Text center color='heroText' fontSize={[14, 16]}>
            Check out the features of your new home base for streaming, merch,
            and content below.
          </Text>
        </Content>
        <Spacer />
        <Link to='/pricing' style={{ textDecoration: 'none' }}>
          <Button
            dataElementLocation={DataElementLocations.HERO}
            dataElementLabel='websitebuilder-fos-gaming-get-started-button-hero'
            dataElementId='websitebuilder-fos-gaming-get-started-button-hero'
            mb={2}
          >
            Get Started
          </Button>
        </Link>
      </Content>
      <Wave variant='spacer' />
      <Wave />
    </Section>
  )
}

export default HeaderPanel

import styled from 'styled-components'
import { color, space, layout, grid, flexbox, position } from 'styled-system'

const Bubble = styled.div({ borderRadius: '50px 50px 0 50px' }, layout, space, color, grid, flexbox, position)

Bubble.defaultProps = {
  bg: 'card',
  position: 'relative'
}

export default Bubble

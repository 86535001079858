import styled from 'styled-components'
import {
  space,
  position,
  layout,
  grid,
  flexbox,
  zIndex,
  textAlign
} from 'styled-system'

const Content = styled.div(
  ({ center }) => center && `text-align: center;`,
  space,
  position,
  layout,
  grid,
  zIndex,
  flexbox,
  textAlign
)

export default Content

import { useStaticQuery, graphql } from 'gatsby'

const useGetBackgroundImages = () =>
  useStaticQuery(graphql`
    query GetImages {
      lightgrad: file(relativePath: { eq: "light/grad.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 500) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      light690: file(relativePath: { eq: "light/690.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 690) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      light960: file(relativePath: { eq: "light/960.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 960) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      light1200: file(relativePath: { eq: "light/1200.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 1200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      light1800: file(relativePath: { eq: "light/1800.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 1800) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      darkgrad: file(relativePath: { eq: "dark/grad.png" }) {
        childImageSharp {
          fixed(width: 500 quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      dark690: file(relativePath: { eq: "dark/690.png" }) {
        childImageSharp {
          fixed(width: 690 quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      dark960: file(relativePath: { eq: "dark/960.png" }) {
        childImageSharp {
          fixed(width: 960 quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      dark1200: file(relativePath: { eq: "dark/1200.png" }) {
        childImageSharp {
          fixed(width: 1200 quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      dark1800: file(relativePath: { eq: "dark/1800.png" }) {
        childImageSharp {
          fixed(width: 1800 quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

export default useGetBackgroundImages

import React from 'react'
import styled from 'styled-components'
import { number, bool, oneOfType, arrayOf } from 'prop-types'
import { get, system } from '@styled-system/core'
import theme from '../theme'

const getSpacer = (n, scale) => {
  const value = get(scale, n, n)
  return value
}

const config = {
  width: {
    property: 'width',
    scale: 'space',
    transform: getSpacer,
    defaultScale: theme.space
  },
  height: {
    property: 'height',
    scale: 'space',
    transform: getSpacer,
    defaultScale: theme.space
  }
}

const spacer = system(config)

const StyledSpacer = styled.div(spacer)

const Spacer = ({ size = 3, horizontal = false, hidden = false }) => {
  if (hidden) return null

  const props = horizontal ? { width: size } : { height: size }

  return <StyledSpacer {...props} />
}

Spacer.propTypes = {
  size: oneOfType([number, arrayOf(number)]),
  horizontal: bool,
  hidden: bool
}

export default Spacer
